import React from 'react';

const TermsOfService = () => {
    return (
        <div className="terms-of-service" style={{ padding: '8rem', backgroundColor: "#132441", color: 'white' }}>
            <p style={{ fontWeight: 'bold', fontSize: '2rem' }}>Terms of Service</p>
            <p>
                Welcome to our Adventure Packages service. By using our service, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Use of Service</p>
            <p>
                Our adventure packages are designed to provide you with exciting and memorable experiences. By booking an adventure package, you agree to use our service responsibly and follow all instructions provided by our guides and partners.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Booking and Payment</p>
            <p>
                All bookings are subject to availability and must be confirmed by us. Payment must be made in full at the time of booking. We reserve the right to cancel or modify bookings if necessary.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Cancellation Policy</p>
            <p>
                If you need to cancel your booking, please contact us as soon as possible. Cancellations may be subject to a cancellation fee. Refunds are not guaranteed and are at our discretion.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Assumption of Risk</p>
            <p>
                By participating in our adventure packages, you acknowledge that such activities involve inherent risks, including but not limited to physical injury, illness, or property damage. You agree to assume all risks and release us from any liability related to your participation.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Liability</p>
            <p>
                We are not liable for any injuries, losses, or damages that may occur during your adventure. Our liability is limited to the maximum extent permitted by law.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Changes to Terms</p>
            <p>
                We may update these terms of service from time to time. Any changes will be posted on this page, and your continued use of our service constitutes acceptance of the updated terms.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Contact Us</p>
            <p>
                If you have any questions or concerns about these terms, please contact us at info@x-peaks.com.
            </p>
        </div>
    );
};

export default TermsOfService;
